<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" md="6">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Top 5 Cities (CTR)</div>
            <div
              class="subtitle-1 font-weight-light"
            >The cities which have the highest engagement/CTR rate</div>
          </template>

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      v-for="(header, index) in mostClickCityHeaders"
                      :key="index"
                    >{{header.text}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in mostClickCityItems" :key="item.name">
                    <td>{{ item.position }}</td>
                    <td>{{ item.city }}</td>
                    <td>{{ item.country }}</td>
                    <td>{{ item.clickCount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <!-- top 5 shortlisted cities -->
      <v-col cols="12" md="6">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Top 5 Shortlisted Cities</div>
            <div
              class="subtitle-1 font-weight-light"
            >The cities which have the highest shortlist count</div>
          </template>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      v-for="(header, index) in mostCompareCityHeader"
                      :key="index"
                    >{{header.text}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in mostCompareCityItems" :key="item.name">
                    <td>{{ item.position }}</td>
                    <td>{{ item.cityInfo.city }}</td>
                    <td>{{ item.cityInfo.country }}</td>
                    <td>{{ item.total }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import analyticsApis from "../../../services/analytics.sevice";
export default {
  name: "Cron",
  data() {
    return {
      organiserCount: 0,
      venueCount: 0,
      citySubscriberCount: 0,
      cityCount: 0,
      completedRFPCount: 0,
      openRFPCount: 0,
      advancedCitySubscriberCount: 0,
      basicCitySubscriberCount: 0,
      total: 0,
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De"
          ],
          series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      rfpCountChart1: {
        data: {
          labels: ["Open RFPs", "Completed RFPs"],
          series: [
            {
              value: 20,
              name: "Open RFPs",
              className: "open-rfp",
              meta: "Open RFPs"
            },
            {
              value: 10,
              name: "Completed RFPs",
              className: "completed-rfp",
              meta: "Completed RFPs"
            }
          ]
        },
        options: {
          labelInterpolationFnc: function(value) {
            return value;
          },
          donut: true,
          donutWidth: 10,
          donutSolid: true,
          startAngle: 60,
          showLabel: true
        },
        responsiveOptions: [
          [
            "screen and (min-width: 640px)",
            {
              chartPadding: 30,
              labelOffset: 100,
              labelDirection: "explode",
              labelInterpolationFnc: function(value) {
                return value;
              }
            }
          ],
          [
            "screen and (min-width: 1024px)",
            {
              labelOffset: 80,
              chartPadding: 20
            }
          ]
        ]
      },
      mostClickCityHeaders: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "City",
          value: "city"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "clickCount",
          align: "right"
        }
      ],
      mostCompareCityHeader: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "City",
          value: "cityInfo.city"
        },
        {
          sortable: false,
          text: "Country",
          value: "cityInfo.country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "total",
          align: "right"
        }
      ],
      headers: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "City",
          value: "city"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "ctr",
          align: "right"
        }
      ],
      mostCompareCityItems: [],
      mostClickCityItems: [],
      tabs: 0,
      tasks: {
        0: [
          {
            text:
              'Sign contract for "What are conference organizers afraposition of?"',
            value: true
          },
          {
            text:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false
          },
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: false
          },
          {
            text: "Create 4 Invisible User Experiences you Never Knew About",
            value: true
          }
        ],
        1: [
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true
          },
          {
            text:
              'Sign contract for "What are conference organizers afraid of?"',
            value: false
          }
        ],
        2: [
          {
            text:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false
          },
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true
          },
          {
            text:
              'Sign contract for "What are conference organizers afraid of?"',
            value: true
          }
        ]
      },
      list: {
        0: false,
        1: false,
        2: false
      }
    };
  },
  computed: {
    rfpCountChart() {
      return {
        data: {
          labels: ["Open RFPs", "Completed RFPs"],
          series: [
            {
              value: this.completedRFPCount,
              name: "Open RFPs",
              className: "open-rfp",
              meta: "Open RFPs"
            },
            {
              value: this.openRFPCount,
              name: "Completed RFPs",
              className: "completed-rfp",
              meta: "Completed RFPs"
            }
          ]
        },
        options: {
          labelInterpolationFnc: function(value) {
            return `a ${value}`;
          },
          donut: true,
          donutWidth: 15,
          donutSolid: true,
          startAngle: 90,
          showLabel: true
        },
        responsiveOptions: [
          [
            "screen and (min-width: 640px)",
            {
              chartPadding: 30,
              labelOffset: 100,
              labelDirection: "explode",
              labelInterpolationFnc: function(value) {
                return value;
              }
            }
          ],
          [
            "screen and (min-width: 1024px)",
            {
              labelOffset: 80,
              chartPadding: 20
            }
          ]
        ]
      };
    }
  },
  mounted() {
    analyticsApis.MostCompareCity().then(res => {
      this.mostCompareCityItems = res.data
        .map((x, i) => {
          return {
            position: i + 1,
            ...x
          };
        })
        .map(x => {
          return {
            ...x,
            cityInfo: {
              ...x.cityInfo,
              city: this.NameCaps(x.cityInfo.city),
              country: this.NameCaps(x.cityInfo.country)
            }
          };
        });
    });
    analyticsApis.mostClickCity().then(res => {
      this.mostClickCityItems = res.data
        .map((x, i) => {
          return {
            position: i + 1,
            ...x
          };
        })
        .map(x => {
          return {
            ...x,
            city: this.NameCaps(x.city),
            country: this.NameCaps(x.country)
          };
        });
    });
    analyticsApis.getCount().then(res => {
      this.organiserCount = res.data.organiserCount;
      this.venueCount = res.data.venueCount;
      this.citySubscriberCount = res.data.citySubscriberCount;
      this.cityCount = res.data.cityCount;
      this.advancedCitySubscriberCount = res.data.advancedCitySubscriberCount;
      this.basicCitySubscriberCount = res.data.basicCitySubscriberCount;
    });
    analyticsApis.getRFPCount().then(res => {
      this.completedRFPCount = res.data.completedRFPCount;
      this.openRFPCount = res.data.openRFPCount;
      this.total = res.data.total;
    });
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    NameCaps(name) {
      let res, firstChar, tail;
      name = name.trim().toLowerCase();
      res = name.split(" ");
      for (var i = 0; i < res.length; i++) {
        firstChar = res[i].substring(0, 1);
        firstChar = firstChar.toUpperCase();
        tail = tail = res[i].substring(1);
        res[i] = firstChar + tail;
        if (i == 0) {
          name = res[i];
        } else {
          name = name + " " + res[i];
        }
      }
      return name;
    }
  }
};
</script>
